import sign from "../_assets/sign.svg";

const Main = () => {
  return (
    <div className='main'>
      <img src={sign} alt="Yes! I'm Looking for Work" />
    </div>
  );
};

export default Main;
